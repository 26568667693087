// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-tags-index-js": () => import("./../../../src/pages/tags/index.js" /* webpackChunkName: "component---src-pages-tags-index-js" */),
  "component---src-templates-mdx-blog-post-js": () => import("./../../../src/templates/mdx-blog-post.js" /* webpackChunkName: "component---src-templates-mdx-blog-post-js" */),
  "component---src-templates-mdx-posts-list-js": () => import("./../../../src/templates/mdx-posts-list.js" /* webpackChunkName: "component---src-templates-mdx-posts-list-js" */),
  "component---src-templates-tags-single-js": () => import("./../../../src/templates/tags-single.js" /* webpackChunkName: "component---src-templates-tags-single-js" */)
}

